import React from "react"
import Layout from "../components/layout"
import ImgThemes from "../assets/images/themes.svg"
import ImgPlugins from "../assets/images/plugins.svg"
import ImgWebsite from "../assets/images/websites.svg"
import SEO from "../components/seo"

export default () => (
  <Layout page="index">
    <SEO title="ZeronPress Agency" />
    {/* <div className="container mt-5">
      <div className="row">
        <div className="jumbotron">
          <p className="lead">
            We enjoy working with companies that are early adopters. It's always
            such an amazing experience to build solutions for people who are
            inovating in their industry.
          </p>
        </div>
      </div>
    </div> */}
    <div className="container home-services py-5">
      <div className="row">
        <div className="col-md-4 home-services-item text-center">
          <img src={ImgThemes} alt="ZeronPress Themes" className="img-fluid" />
          <h3>WordPress Themes</h3>
          <p>
            Themes built with our framework stand out with beautiful and
            engaging design
          </p>
        </div>
        <div className="col-md-4 home-services-item text-center">
          <img
            src={ImgPlugins}
            alt="ZeronPress Plugins"
            className="img-fluid"
          />
          <h3>WordPress Plugins</h3>
          <p>
            We create custom plugins to extend or add new functionality to your
            website.
          </p>
        </div>
        <div className="col-md-4 home-services-item text-center">
          <img
            src={ImgWebsite}
            alt="ZeronPress WordPress Website"
            className="img-fluid"
          />
          <h3>WordPress Websites</h3>
          <p>
            We provide complete solutions by building websites from beginning to
            end
          </p>
        </div>
      </div>
    </div>
  </Layout>
)
